body {
  background-color: #f9f3f3b6;
  margin: 0;
  padding: 20px;
  min-height: 100vh;
}

.App {
  padding: 20px;
}

.login-container, .timesheet-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-container form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.login-container form div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.login-container input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
}

button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  margin: 10px 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: white;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  cursor: pointer;
  user-select: none;
  padding: 8px 12px;
}

th:hover {
  background-color: #e0e0e0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.week-selector {
  display: flex;
  align-items: center;
  gap: 15px;
}

.week-selector label {
  font-weight: 500;
  color: #444;
  white-space: nowrap;
}

.header select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  min-width: 200px;
  background: white;
}

.header select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.login-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.login-logo {
  width: 100px;
  height: auto;
  margin-right: 20px;
}

.login-title {
  margin: 0;
  color: #333;
}

.timesheet-actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.timesheet-actions button {
  min-width: 150px;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loading {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #666;
}

.sort-icon {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
  width: 12px;
  text-align: center;
  color: #999;
}

.sort-icon.active {
  color: #007bff;
}

.sort-icon.inactive {
  opacity: 0.5;
}

.timesheet-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow-x: auto;
}

.timesheet-form {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.form-group {
  flex: 1;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #444;
}

.form-group input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

th {
  background: #f4f4f4;
  padding: 12px;
  font-weight: 600;
  text-align: left;
  border-bottom: 2px solid #ddd;
  cursor: pointer;
  user-select: none;
}

td {
  padding: 12px;
  border-bottom: 1px solid #eee;
}

tr:hover {
  background: #f8f9fa;
}

button {
  background: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

button:hover {
  background: #0056b3;
}

button:disabled {
  background: #ccc;
}

.loading {
  text-align: center;
  padding: 40px;
  color: #666;
}

.error {
  color: #dc3545;
  padding: 10px;
  margin: 10px 0;
  background: #f8d7da;
  border-radius: 4px;
}

.header-left {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.logo-section {
  display: flex;
  align-items: center;
  gap: 20px;
}

.timesheet-logo {
  width: 100px;
  height: auto;
  object-fit: contain;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .header-left {
    width: 100%;
  }

  .week-selector {
    margin-top: 15px;
  }

  th, td {
    min-width: 120px;
    white-space: nowrap;
  }

  th:nth-child(1),
  td:nth-child(1) {
    min-width: 180px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    min-width: 90px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    min-width: 100px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    min-width: 120px;
  }

  th:nth-child(5),
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(8),
  td:nth-child(5),
  td:nth-child(6),
  td:nth-child(7),
  td:nth-child(8) {
    min-width: 160px;
  }

  th:nth-child(9),
  td:nth-child(9) {
    min-width: 140px;
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  z-index: 1000;
}

.total-hours {
  background: #f8f9fa;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
}

.total-hours > div:first-child {
  font-weight: bold;
}

.hours-note {
  font-weight: normal;
  font-style: italic;
  color: #666;
  font-size: 0.9em;
  margin-top: 4px;
}

.logo-section h2 {
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  color: #2c3e50;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  letter-spacing: 0.5px;
}

.login-logo {
  width: 100px;
  height: auto;
  object-fit: contain;
}
